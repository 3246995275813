import CloseIcon from '@material-ui/icons/RemoveCircle'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  leftIcon: {
    color: 'red',
  },
  button: {
    position: 'absolute',
    minWidth: 20,
    borderRadius: 40,
    zIndex: 1,
  },
}))

const RemoveButton = (props) => {
  const classes = useStyles()

  return (
    <Button className={classes.button} {...props}>
      <CloseIcon className={classes.leftIcon} />
    </Button>
  )
}

export default RemoveButton
