import { minLength, maxLength } from 'react-admin'

export const length = (length, message = `Must be ${length} characters`) => [
  minLength(length, message),
  maxLength(length, message),
]

export const isInteger = (value) => {
  if (!value) {
    return undefined
  }

  const n = Math.floor(Number(value))
  const integer = n !== Infinity && n === parseFloat(value) && n > 0

  if (!integer) {
    return 'Must be an integer'
  }

  return undefined
}
