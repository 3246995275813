import React, { Children, cloneElement, useState, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { useInput, useTranslate } from 'ra-core'

import { Labeled, sanitizeInputRestProps } from 'react-admin'
import FileInputPreview from './FileInputPreview'

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    root: { width: '100%' },
    helperText: {
      marginBottom: 16,
    },
  }),
  { name: 'RaFileInput' }
)

const CustomFileInput = (props) => {
  const {
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const [errors, setErrors] = useState(false)

  // turn a browser dropped file structure into expected structure
  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file
    }

    const { source, title } = Children.only(children).props

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    }

    if (title) {
      transformedFile[title] = file.name
    }

    return transformedFile
  }

  const transformFiles = (files) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  })

  const files = value ? (Array.isArray(value) ? value : [value]) : []

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    if (multiple) {
      onChange(updatedFiles)
    } else {
      onChange(updatedFiles[0])
    }

    rejectedFiles.forEach((file) => {
      const tooBig = file.size > maxSize
      const wrongFileExtension = !accept.includes(
        `.${file.name.split('.').pop()}`
      )

      if (tooBig) {
        setErrors(true)
      }

      if (wrongFileExtension) {
        setErrors(true)
      }
    })

    if (rejectedFiles.length === 0) {
      setErrors(false)
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event)
    }
  }

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? Children.only(children)
      : undefined

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  })

  return (
    <div className={classnames(classes.root, className)}>
      <Labeled
        id={id}
        label={label}
        className={classes.root}
        source={source}
        resource={resource}
        isRequired={isRequired}
        meta={meta}
        {...sanitizeInputRestProps(rest)}
      >
        <>
          {!value && (
            <>
              <div
                data-testid='dropzone'
                className={classes.dropZone}
                {...getRootProps()}
              >
                <input
                  id={id}
                  {...getInputProps({
                    ...inputProps,
                    ...inputPropsOptions,
                  })}
                />
                {placeholder ? (
                  placeholder
                ) : multiple ? (
                  <p>{translate(labelMultiple)}</p>
                ) : (
                  <p>{translate(labelSingle)}</p>
                )}
              </div>
            </>
          )}
          {children && (
            <div className='previews'>
              {files.map((file, index) => (
                <FileInputPreview
                  key={index}
                  file={file}
                  onRemove={() => onChange(null)}
                >
                  {cloneElement(childrenElement, {
                    record: file,
                  })}
                </FileInputPreview>
              ))}
            </div>
          )}
        </>
      </Labeled>
      {!value && (
        <FormHelperText
          className={`${classes.helperText} ${errors && 'Mui-error'}`}
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  )
}

CustomFileInput.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
}

export default CustomFileInput
