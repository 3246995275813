import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'

const BirdsTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#2e2e2e',
    },
    secondary: {
      main: '#000000',
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: '40px!important',
        width: '40px',
      },
    },
    MuiButton: {
      text: {
        padding: 6,
      },
    },
    MuiTab: {
      root: {
        minWidth: '100px!important',
      },
    },
    RaLayout: {
      content: {
        maxWidth: 1000,
      },
    },
    RaSimpleFormIterator: {
      index: {
        display: 'none',
      },
      line: {
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: -20,
        marginRight: -20,
        borderBottom: 'dashed 2px lightGray',
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: 'lightGray',
        marginBottom: 40,

        '&:last-child': {
          border: 'none',
        },
      },
    },
    RaTabbedForm: {
      content: {
        marginTop: 20,
      },
    },
    RaLabeled: {
      value: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
        paddingTop: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: '#2e2e2e',
      },
      filled: {
        color: '#2e2e2e!important',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: 'lightgray',
      },
    },
    RaFileInput: {
      root: {},
      dropZone: {
        maxWidth: 350,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        marginTop: 20,
        marginBottom: 20,
        paddingBottom: 10,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'rgba(0,0,0,0.37)',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#2e2e2e',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      },
      preview: {
        flex: 1,
        margin: 0,
      },
    },
    RaAutocompleteArrayInput: {
      chipContainerFilled: {
        margin: '12px 12px 10px 0',
      },
      inputInput: {
        paddingBottom: '12px',
        paddingTop: '12px',
      },
    },
    RaImageInput: {
      dropZone: {
        padding: 10,
      },
    },
  },
})

export default BirdsTheme
