import React from 'react'
import { Login, LoginForm } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  main: { background: '#000000' },
}

const CustomLoginPage = (props) => (
  <Login loginForm={<LoginForm />} {...props} />
)

export default withStyles(styles)(CustomLoginPage)
