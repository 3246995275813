const FileInputField = ({ record }) => {
  const title = record.rawFile ? record.rawFile.name : record.name
  const source = record.undefined ? record.undefined : record.src

  return (
    <>
      <a href={source} title={title} target='_blank' rel='noreferrer'>
        {title}
      </a>
    </>
  )
}

export default FileInputField
