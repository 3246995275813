import {
  Toolbar as ReactAdminToolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  saveButton: {
    marginRight: 16,
  },
}))

const Toolbar = (props) => {
  const { pristine, mutationMode, ...rest } = props
  const classes = useStyles()

  return (
    <ReactAdminToolbar {...rest} className={classes.toolbar}>
      <div>
        <SaveButton
          {...rest}
          disabled={pristine}
          label='Save'
          redirect={false}
          className={classes.saveButton}
        />
        <SaveButton
          {...rest}
          disabled={pristine}
          label='Save and Quit'
          redirect='list'
        />
      </div>
      <DeleteButton redirect='list' />
    </ReactAdminToolbar>
  )
}

export default Toolbar
