import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { DataProvider, AuthenticationProvider } from './data'
import BirdsTheme from './theme'
import { ReactComponent as BirdSVG } from './core/assets/bird.svg'
import { LoginPage } from './core'
import { BirdEdit, BirdList, BirdCreate } from './modules/birds'
import { ClusterEdit, ClusterList, ClusterCreate } from './modules/clusters'

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
    allowMissing: true,
    onMissingKey: (key, _, __) => '',
  })

  return (
    <Admin
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      dataProvider={DataProvider}
      authProvider={AuthenticationProvider}
      theme={BirdsTheme}
    >
      <Resource
        name='bird'
        list={BirdList}
        edit={BirdEdit}
        create={BirdCreate}
        icon={BirdSVG}
      />
      <Resource
        name='cluster'
        list={ClusterList}
        edit={ClusterEdit}
        create={ClusterCreate}
      />
    </Admin>
  )
}

export default App
