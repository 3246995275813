const refreshTokenStorageKey = 'refreshTokenCMS'
const accessTokenStorageKey = 'accessTokenCMS'

export const getRefreshTokenFromStorage = () =>
  localStorage.getItem(refreshTokenStorageKey)

export const setRefreshTokenToStorage = (token) =>
  localStorage.setItem(refreshTokenStorageKey, token)

export const removeRefreshTokenFromStorage = () =>
  localStorage.removeItem(refreshTokenStorageKey)

export const getAccessTokenFromStorage = () =>
  localStorage.getItem(accessTokenStorageKey)

export const setAccessTokenToStorage = (token) =>
  localStorage.setItem(accessTokenStorageKey, token)

export const removeAccessTokenFromStorage = () =>
  localStorage.removeItem(accessTokenStorageKey)
