import deepEqual from 'deep-equal'

/**
 * Comparison of 2 arrays of resource objects
 * Based on the changes, the function calls either update, delete or create
 */
const checkArray = async (resource, params) => {
  const {
    originalArray = [],
    newArray = [],
    updateCallback,
    deleteCallback,
    createCallback,
    imageType = null,
  } = params
  let createArray = newArray

  for (let i = 0; i < originalArray.length; i++) {
    const originalElement = originalArray[i]
    let found = false

    for (let j = 0; j < newArray.length; j++) {
      const newElement = newArray[j]

      if (newElement && newElement.id === originalElement.id) {
        found = true
        createArray = createArray.filter(
          (el) => el && el.id !== originalElement.id
        )

        if (!deepEqual(originalElement, newElement)) {
          await updateCallback(resource, {
            originalMedia: originalElement,
            newMedia: newElement,
          })
        }

        break
      }
    }

    if (!found) {
      await deleteCallback(resource, originalElement)
    }
  }

  if (createArray.length > 0) {
    await Promise.all(
      createArray.map(
        (createElement) =>
          createElement &&
          createCallback(resource, { ...createElement, imageType })
      )
    )
  }
}

/**
 * Create form data from file before sending to API
 */
const createFormData = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return formData
}

export { checkArray, createFormData }
