import SimilarSoundProvider from './SimilarSoundProvider'
import { Methods, Data } from './services'

const apiUrl = process.env.REACT_APP_BASE_URL
const apiKeyCMS = process.env.REACT_APP_API_KEY_CMS

/**
 * Media data provider, serves images, thumbnails, sounds, video ...
 */
const MediaProvider = {
  birdId: null,

  async getMedia(resource, { id, caption, description }) {
    if (resource !== 'video') {
      return {
        file: {
          src: `${apiUrl}/bird/${this.birdId}/${resource}/${id}?apiKey=${apiKeyCMS}`,
        },
        id,
        caption,
        description,
      }
    }

    const src = await fetch(
      `${apiUrl}/bird/${this.birdId}/${resource}/${id}/thumbnail?apiKey=${apiKeyCMS}`,
      { cache: 'no-cache' }
    ).then((res) => res.url)
    const videoData = !src ? {} : { videoThumbnail: { src } }

    return {
      file: {
        src: `${apiUrl}/bird/${this.birdId}/${resource}/${id}?apiKey=${apiKeyCMS}`,
      },
      id,
      caption,
      description,
      ...videoData,
    }
  },

  getMediaArray(resource, records) {
    if (records.length === 0) {
      return []
    }

    return Promise.all(
      records.map(async (record) => {
        if (record === null) {
          return null
        }

        return await this.getMedia(resource, {
          id: record.id,
          caption: record.caption,
          description: record.description,
        }).then((res) => res)
      })
    )
  },

  async getMaps(sixLetterCode) {
    const response = await fetch(
      `${apiUrl}/sixlettercode/${sixLetterCode}/map?apiKey=${apiKeyCMS}`,
      { cache: 'no-cache' }
    ).then((res) => res)

    const body = await response.json()

    return body.map((record) => ({
      file: {
        src: `${apiUrl}/sixlettercode/${sixLetterCode}/map/${record.id}?apiKey=${apiKeyCMS}`,
      },
      id: record.id,
      caption: record.caption,
    }))
  },

  async getSounds(sixLetterCode) {
    const response = await fetch(
      `${apiUrl}/sixlettercode/${sixLetterCode}/sound?apiKey=${apiKeyCMS}`,
      { cache: 'no-cache' }
    ).then((res) => res)

    const body = await response.json()

    return body.map((record) => ({
      file: {
        src: `${apiUrl}/sixlettercode/${sixLetterCode}/sound/${record.id}?apiKey=${apiKeyCMS}`,
      },
      id: record.id,
      caption: record.caption,
      description: record.description,
      sonogramUrl: record.sonogramUrl,
      sixLetterCode: record.sixLetterCode,
    }))
  },

  updateSingleMedia(resource, { imageType, originalMedia, newMedia }) {
    // New image
    if (!originalMedia && !!newMedia) {
      return this.createMedia(resource, {
        ...newMedia,
        imageType,
      })
    }

    // Image deleted
    if (!newMedia && !!originalMedia) {
      return this.deleteMedia(resource, { id: originalMedia.id })
    }

    if (originalMedia && newMedia) {
      return this.updateMediaData(resource, {
        originalMedia,
        newMedia: { ...newMedia, imageType },
      })
    }
  },

  async updateMediaData(resource, { originalMedia, newMedia }) {
    // Delete the original
    if (originalMedia.file && !newMedia.file) {
      await this.deleteMedia(resource, originalMedia)
    }

    // Replacements are not allowed, delete the original and upload the new one
    if (newMedia.file && newMedia.file.src !== originalMedia.file.src) {
      await this.deleteMedia(resource, originalMedia)
      await this.createMedia(resource, newMedia)
    }

    // Caption update
    if (newMedia.caption && newMedia.caption !== originalMedia.caption) {
      if (resource === 'sound') {
        await this.updateSoundCaption({
          sixLetterCode: newMedia.sixLetterCode,
          caption: newMedia.caption,
          id: newMedia.id,
        })
      } else {
        await this.updateCaption(resource, {
          caption: newMedia.caption,
          id: newMedia.id,
        })
      }
    }

    // Description update
    if (
      newMedia.description &&
      newMedia.description !== originalMedia.description
    ) {
      if (resource === 'sound') {
        await this.updateSoundDescription({
          sixLetterCode: newMedia.sixLetterCode,
          description: newMedia.description,
          id: newMedia.id,
        })
      } else {
        await this.updateDescription(resource, {
          description: newMedia.description,
          id: newMedia.id,
        })
      }
    }

    if (resource !== 'video') {
      return
    }

    // video thumbnail
    // Create new or update video thumbnail
    if (
      (newMedia.videoThumbnail && !originalMedia.videoThumbnail?.src) ||
      (newMedia.videoThumbnail?.src &&
        originalMedia.videoThumbnail?.src &&
        newMedia.videoThumbnail.src !== originalMedia.videoThumbnail.src)
    ) {
      await this.createVideoThumbnail({
        rawFile: newMedia.videoThumbnail.rawFile,
        id: newMedia.id,
      })
    }
  },

  async updateAudioData(resource, { originalMedia, newMedia }) {
    await this.updateMediaData(resource, { originalMedia, newMedia })

    // Similar sounds
    const newSimilarSounds = newMedia.similarSounds.filter(
      ({ id }) => !originalMedia.similarSounds.find((item) => item.id === id)
    )
    const removedSimilarSounds = originalMedia.similarSounds.filter(
      ({ id }) => !newMedia.similarSounds.find((item) => item.id === id)
    )

    await Promise.all([
      ...removedSimilarSounds.map((similarSound) =>
        SimilarSoundProvider.delete(
          originalMedia.id,
          similarSound,
          newMedia.sixLetterCode
        )
      ),
      ...newSimilarSounds.map((similarSound) =>
        SimilarSoundProvider.create(
          originalMedia.id,
          similarSound,
          newMedia.sixLetterCode
        )
      ),
    ])

    // Sonogram
    if (!originalMedia.sonogram.src && !newMedia.sonogram.src) {
      return
    }

    // Delete the original
    if (originalMedia.sonogram && !newMedia.sonogram) {
      return this.deleteSonogram({ id: originalMedia.id })
    }

    // Create new sonogram
    if (newMedia.sonogram && !originalMedia.sonogram.src) {
      return this.createSonogram({
        rawFile: newMedia.sonogram.rawFile,
        id: newMedia.id,
      })
    }

    // Replacements are not allowed, delete the original and upload the new one
    if (
      newMedia.sonogram.src &&
      originalMedia.sonogram.src &&
      newMedia.sonogram.src !== originalMedia.sonogram.src
    ) {
      await this.deleteSonogram({ id: originalMedia.id })
      await this.createSonogram({
        rawFile: newMedia.sonogram.rawFile,
        id: newMedia.id,
      })
    }
  },

  createAudioData(resource, { sonogram, similarSounds, ...rest }) {
    return this.createMedia(resource, { ...rest }).then(async (response) => {
      const { id } = response.json

      if (!!similarSounds) {
        await Promise.all(
          similarSounds.map((similarSound) =>
            SimilarSoundProvider.create(id, similarSound)
          )
        )
      }

      if (!sonogram) {
        return
      }

      await this.createSonogram({
        rawFile: sonogram.rawFile,
        id,
      })
    })
  },

  updateMediaArray(resource, { imageType, originalArray, newArray }) {
    return Data.checkArray(resource, {
      originalArray,
      newArray,
      updateCallback: this.updateMediaData.bind(this),
      createCallback: this.createMedia.bind(this),
      deleteCallback: this.deleteMedia.bind(this),
      imageType,
    })
  },

  updateMap(sixLetterCode, { originalArray, newArray }) {
    return Data.checkArray(sixLetterCode, {
      originalArray,
      newArray,
      updateCallback: this.updateCallbackMap,
      createCallback: this.createCallbackMap,
      deleteCallback: this.deleteCallbackMap,
    })
  },

  updateAudioArray(resource, { originalArray, newArray }) {
    return Data.checkArray(resource, {
      originalArray,
      newArray,
      updateCallback: this.updateAudioData.bind(this),
      createCallback: this.createAudioData.bind(this),
      deleteCallback: this.deleteMedia.bind(this),
    })
  },

  updateCallbackMap(sixLetterCode, updateElement) {
    Methods.patch(
      `sixlettercode/${sixLetterCode}/map/${updateElement.originalMedia.id}`,
      {
        caption: updateElement.newMedia.caption,
      }
    )
  },

  createCallbackMap(sixLetterCode, createElement) {
    Methods.putFile(
      `sixlettercode/${sixLetterCode}/map`,
      createElement.file.rawFile
    ).then(async ({ json }) => {
      const id = json.id
      const caption = createElement.caption

      await Methods.patch(`sixlettercode/${sixLetterCode}/map/${id}`, {
        caption,
      })
    })
  },

  deleteCallbackMap(sixLetterCode, originalElement) {
    Methods.remove(`sixlettercode/${sixLetterCode}/map/${originalElement.id}`)
  },

  updateCaption(resource, { id, caption }) {
    return Methods.patch(`bird/${this.birdId}/${resource}/${id}`, { caption })
  },

  updateSoundCaption({ sixLetterCode, id, caption }) {
    return Methods.patch(`sixlettercode/${sixLetterCode}/sound/${id}`, {
      caption,
    })
  },

  updateDescription(resource, { id, description }) {
    return Methods.patch(`bird/${this.birdId}/${resource}/${id}`, {
      description,
    })
  },

  updateSoundDescription({ sixLetterCode, id, description }) {
    return Methods.patch(`sixlettercode/${sixLetterCode}/sound/${id}`, {
      description,
    })
  },

  getSonogram({ id }) {
    return {
      src: `${apiUrl}/bird/${this.birdId}/sound/${id}/sonogram?apiKey=${apiKeyCMS}`,
      id: id,
    }
  },

  async getSonogramArray(soundRecords) {
    if (soundRecords.length === 0) {
      return []
    }

    return soundRecords.map((record) => {
      if (record === null) {
        return null
      }

      return this.getSonogram({ id: record.id })
    })
  },

  createSonogram({ id, rawFile }) {
    return Methods.putFile(`bird/${this.birdId}/sound/${id}/sonogram`, rawFile)
  },

  deleteSonogram({ id }) {
    return Methods.remove(`bird/${this.birdId}/sound/${id}/sonogram`)
  },

  updateSonogram(resource, { id, rawFile }) {
    return Methods.putFile(
      `bird/${this.birdId}/${resource}/${id}/sonogram`,
      rawFile
    )
  },

  createVideoThumbnail({ id, rawFile }) {
    return Methods.putFile(`bird/${this.birdId}/video/${id}/thumbnail`, rawFile)
  },

  createMedia(
    resource,
    { file, caption, description, imageType, videoThumbnail }
  ) {
    return Methods.putFile(
      `bird/${this.birdId}/${resource}`,
      file.rawFile
    ).then(({ json }) => {
      const id = json.id
      if (videoThumbnail) {
        this.createVideoThumbnail({ id, rawFile: videoThumbnail.rawFile })
      }

      return Methods.patch(`bird/${this.birdId}/${resource}/${id}`, {
        caption,
        description,
        birdImageType: imageType,
      })
    })
  },

  deleteMedia(resource, { id }) {
    return Methods.remove(`bird/${this.birdId}/${resource}/${id}`)
  },
}

export default MediaProvider
