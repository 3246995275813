import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Constants from '../const'
import CustomFileInput from './CustomFileInput'

const useStyles = makeStyles(
  (theme) => ({
    root: { width: '100%' },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {
      display: 'inline-block',
    },
  }),
  { name: 'RaImageInput' }
)

const CustomImageInput = (props) => {
  const classes = useStyles(props)

  return (
    <CustomFileInput
      labelMultiple='ra.input.image.upload_several'
      labelSingle='ra.input.image.upload_single'
      maxSize={20000000}
      helperText={Constants.MEDIA_TYPE_HELPER_TEXT.IMAGE}
      classes={classes}
      {...props}
    />
  )
}

export default CustomImageInput
