import { MapContainer as Map } from 'react-leaflet'
import { useCallback } from 'react'
import { useInput } from 'react-admin'
import { FormHelperText } from '@material-ui/core'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import LocationMap from './LocationMap'

const MapContainer = ({ id, ...props }) => {
  const {
    input: { onChange, value },
  } = useInput(props)

  const handleOnChange = useCallback(
    (value) => {
      onChange(value)
    },
    [onChange]
  )

  return (
    <>
      <FormHelperText>
        Used in addition with EBird data to better specify where this species
        can be found. If I remember correctly, Fox Sparrow is a good example for
        this, because based on the region (e.g. East Coast / West Coast) it has
        a different appearance. Since all Fox Sparrows have the same 4 letter
        code, we can't distinguish which one to show purely on EBird data, so
        region is used as an additional filter.
      </FormHelperText>
      <br />
      <Map center={[34.6, -95]} zoom={4} scrollWheelZoom={false}>
        <LocationMap onChange={handleOnChange} value={value} />
      </Map>
    </>
  )
}

export default MapContainer
