import * as React from 'react'
import { validateModel } from '../validators'
import { FileInput } from 'react-admin'
import { FileInputField, Constants } from '../../../core'

const Model = ({ id, ...props }) => {
  return (
    <FileInput
      validate={validateModel}
      label=''
      maxSize={20000000}
      helperText={Constants.MEDIA_TYPE_HELPER_TEXT.MODEL}
      source='modelFiles'
      labelMultiple='ra.input.file.upload_several'
      multiple
    >
      <FileInputField />
    </FileInput>
  )
}

export default Model
