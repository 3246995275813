import { cloneElement } from 'react'
import {
  useCreateController,
  useNotify,
  useRedirect,
  EditContextProvider,
  Loading,
} from 'react-admin'
import { DataProvider, Relationships } from '../../../data'

const CustomClusterCreate = (props) => {
  const controllerProps = useCreateController(props)

  const {
    basePath,
    loading,
    record,
    resource,
    saving,
    version,
  } = controllerProps

  const notify = useNotify()
  const redirect = useRedirect()

  const handleSuccess = (cluster) => {
    const { id: clusterId } = cluster
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect(`/cluster`, basePath, clusterId, cluster)
  }

  const resolveError = (
    error,
    defaultMessage = 'ra.notification.http_error'
  ) => {
    if (typeof error === 'string') {
      return error
    }
    return error.message || defaultMessage
  }

  const handleFailure = (error) => {
    notify(resolveError(error), 'warning', {
      _: resolveError(error, undefined),
    })
  }

  const handleSaveCluster = (clusterData) => {
    DataProvider.create('cluster', {
      data: clusterData,
    })
      .then(({ data }) => Relationships.updateRelationships('cluster', data))
      .then(() => handleSuccess(clusterData))
      .catch(handleFailure)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save: handleSaveCluster,
          saving,
          version,
        })}
      </div>
    </EditContextProvider>
  )
}

export default CustomClusterCreate
