const ACCEPT_IMAGE = ['.jpg', '.jpeg', '.png', '.webp', '.gif']

const ACCEPT_AUDIO = ['.wav', '.mp3', '.ogg']
const ACCEPT_VIDEO = ['.mp4', '.avi', '.mkv']
const ACCEPT_MODEL = [
  'model/mtl',
  'model/obj',
  'text/plain',
  'application/object',
]

const MEDIA_TYPE_HELPER_TEXT = {
  IMAGE:
    'Supported file formats are .jpg, .jpeg, .png, .gif and .webp. Your upload should not exceed 20MB in total size.',
  AUDIO:
    'Supported file formats are .wav, .mp3, .ogg. Your upload should not exceed 20MB in total size.',
  VIDEO:
    'Supported file formats are .mp4, .avi and .mkv. Your upload should not exceed 20MB in total size.',
  MODEL: 'Your files should not exceed 20MB in size.',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ACCEPT_IMAGE,
  ACCEPT_AUDIO,
  ACCEPT_VIDEO,
  ACCEPT_MODEL,
  MEDIA_TYPE_HELPER_TEXT,
}
