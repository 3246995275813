import * as React from 'react'
import { AutocompleteArrayInput, useRecordContext } from 'react-admin'
import { Options } from '../'
import DataProvider from '../../data/DataProvider'

const SUGGESTION_LIMIT = 10

const SimilarBirdsInput = () => {
  const record = useRecordContext()
  const [selectedBirds, setSelectedBirds] = React.useState([])
  const [searchChoices, setSearchChoices] = React.useState([])

  React.useEffect(() => {
    if (record.similarBirds) {
      setSelectedBirds(record.similarBirds)
    }
  }, [record])

  const handleSelectOnChange = React.useCallback(
    (value) => {
      if (!value || value.length < 3) {
        return
      }

      DataProvider.getList('bird', {
        pagination: { page: 1, perPage: SUGGESTION_LIMIT },
        sort: { field: 'id', order: 'ASC' },
        filter: { name: value },
      }).then(({ data }) => {
        const filtered = data.filter(
          (choice) => !selectedBirds.includes(choice.id)
        )
        setSearchChoices(filtered)
      })
    },
    [selectedBirds]
  )

  const handleOnChange = React.useCallback(
    (values) => {
      const birds = values.map((value) => {
        return [...selectedBirds, ...searchChoices].filter(
          (choice) => choice.id === value
        )[0]
      })
      setSelectedBirds(birds)
    },
    [searchChoices, selectedBirds]
  )

  return (
    <>
      <h3>Similar birds</h3>
      <AutocompleteArrayInput
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2
        }}
        suggestionLimit={SUGGESTION_LIMIT}
        label=''
        source='similarBirdIds'
        onChange={handleOnChange}
        onInputValueChange={handleSelectOnChange}
        choices={[...selectedBirds, ...searchChoices]}
        optionText={Options.optionRenderer}
        optionValue='id'
        fullWidth
      />
    </>
  )
}

export default SimilarBirdsInput
