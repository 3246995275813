import * as React from 'react'
import { Button, FormDataConsumer } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline'
import DataProvider from '../../data/DataProvider'
import { extractIndex } from '../services/arrayFieldIndex'

const useStyles = makeStyles((theme) => ({
  similarSoundContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    marginBottom: '10px',
    background: '#eee',
    borderRadius: '4px',
  },
}))

const SimilarSoundsList = ({ source, removeSimilarSound, ...rest }) => {
  const [birds, setBirds] = React.useState([])
  const [sounds, setSounds] = React.useState([])
  const classes = useStyles(rest)
  const soundIndex = extractIndex(source)

  React.useEffect(() => {
    const fetchSounds = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/sixlettercode/sound/all?apiKey=${process.env.REACT_APP_API_KEY_CMS}`,
          { cache: 'no-cache' }
        )
        if (!response.ok) {
          throw new Error('Sounds get all response was not 200 OK')
        }
        const jsonData = await response.json()
        setSounds(jsonData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSounds()

    DataProvider.getList('bird', {
      pagination: {},
      sort: {},
      filter: {},
    }).then(({ data }) => setBirds(data))
  }, [])

  const handleRemoveClick = (id) => () =>
    removeSimilarSound({ source, soundIndex, soundId: id })

  if (!birds.length || !sounds.length) return null

  return (
    <div>
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData || !formData.sounds || !formData.sounds[soundIndex])
            return null

          if (!formData.sounds[soundIndex].similarSounds?.length) return null

          return formData.sounds[soundIndex].similarSounds.map(
            ({ id, similarSoundId, description }) => {
              const sound = sounds.find(
                (item) =>
                  item.id === id || item.id === parseInt(similarSoundId, 10)
              )

              if (!sound?.sixLetterCode) {
                return null
              }

              return (
                <div key={id} className={classes.similarSoundContainer}>
                  <div className={classes.textWrap}>
                    <b>
                      Six letter code: {sound?.sixLetterCode}
                      <br />
                      Caption: {sound?.caption}
                    </b>
                    <br />
                    {description}
                  </div>
                  <Button
                    label='remove'
                    alignIcon='right'
                    startIcon={<RemoveIcon />}
                    onClick={handleRemoveClick(id)}
                  />
                </div>
              )
            }
          )
        }}
      </FormDataConsumer>
    </div>
  )
}

export default SimilarSoundsList
