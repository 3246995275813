import { Relationships, Methods, Normalizr } from './services'

/**
 * Main resource data provider, used by react-admin
 */
const DataProvider = {
  getQuery: (
    page,
    perPage,
    order,
    orderDirection,
    field,
    filterExists,
    name
  ) => {
    let query = ''

    if (filterExists && name) {
      query += `name=${name}&`
    }

    if (page && perPage) {
      const offset = (page - 1) * perPage
      query += `page[limit]=${perPage}&page[offset]=${offset}`
    }

    if (order && field) {
      query += `&sort=${orderDirection}${field}`
    }

    return query
  },
  getOne: (resource, { id }) =>
    Methods.get(`${resource}/${id}`).then(({ json }) => {
      let data = Normalizr.normalized(resource, json)

      return Relationships.getRelationships(resource, data).then(
        (relationships) => {
          data = { ...data, ...relationships }
          return {
            data,
          }
        }
      )
    }),

  update: async (resource, { id, data }) => {
    return Methods.patch(`${resource}/${id}`, data).then(({ json }) => {
      const data = Normalizr.normalized(resource, json)

      return {
        data,
      }
    })
  },
  create: (resource, { data }) =>
    Methods.post(resource, data).then(({ json }) => ({
      data: { ...data, id: json.id },
    })),
  copy: (id) => Methods.get(`bird/copy/${id}`),
  delete: (resource, { id }) =>
    Methods.remove(`${resource}/${id}`).then(({ json }) => ({
      data: { ...json, id },
    })),
  deleteMany: (resource, params) => {
    const removeAll = params.ids.map((id) =>
      Methods.remove(`${resource}/${id}`)
    )

    return Promise.all(removeAll).then(() => {
      return { data: [] }
    })
  },
  getList: (resource, params = { pagination: {}, sort: {}, filter: {} }) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const { filter } = params
    const { name } = filter

    const filterExists = filter && Object.keys(filter).length !== 0

    const orderDirection = order === 'ASC' ? '+' : '-'

    let url = filterExists ? `${resource}/search` : `${resource}/`

    const query = DataProvider.getQuery(
      page,
      perPage,
      order,
      orderDirection,
      field,
      filterExists,
      name
    )

    return Methods.get(url, query).then(({ headers, json }) => {
      if (json.length === 0) {
        return { data: [], total: 0 }
      }

      let values = Object.values(
        Normalizr.normalizedList(resource, json)[resource]
      )
      let total = Number(headers.get('Content-Range')) || values.length

      return { data: values, total }
    })
  },
  getAll: (resource) => {
    let url = `${resource}/all`

    return Methods.get(url).then(({ headers, json }) => {
      let values = Object.values(
        Normalizr.normalizedList(resource, json)[resource]
      )
      let total = headers.get('Content-Range') || values.length

      return { data: values, total }
    })
  },
  getMany: (resource, { ids }) => {
    if (ids.length === 0) {
      return { data: [] }
    }

    const query = `filter[id] = ${ids} `

    return Methods.get(resource, query).then(({ json }) => {
      const values = Object.values(
        Normalizr.normalizedList(resource, json)[resource]
      )

      return {
        data: values,
      }
    })
  },
}

export default DataProvider
