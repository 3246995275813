import React from 'react'
import { FormHelperText } from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  required,
  maxLength,
} from 'react-admin'
import ClusterBirdInput from '../components/ClusterBirdInput'
import { birdCategoryChoices, shapeChoices } from '../const'
import CustomClusterCreate from './CustomClusterCreate'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  helperText: { width: '100%' },
  helperTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
}))

const ClusterCreate = (props) => {
  const classes = useStyles(props)

  return (
    <CustomClusterCreate {...props} mutationMode='pessimistic'>
      <SimpleForm>
        <TextInput source='name' validate={[required(), maxLength(255)]} />
        <RadioButtonGroupInput
          source='birdCategory'
          choices={birdCategoryChoices}
          fullWidth
          helperText='Bird category and cluster type are used for grouping clusters. E.g. all Visual-Color (cluster type) + Yard & Field (bird category) clusters will be shown in the same group.'
          validate={required()}
        />
        <RadioButtonGroupInput
          fullWidth
          validate={required()}
          source='clusterType'
          choices={shapeChoices}
        />
        <FormHelperText className={classes.helperTitle}>Birds</FormHelperText>
        <FormHelperText className={classes.helperText}>
          RepresantitiveGroup is used in addition with representativeIndex for
          displaying representative birds. For each cluster we're displaying 4
          representative birds, and representative group is used to group
          similar birds to avoid showing 4 very similar birds as
          representatives. It has values 1-4 (1 - bird in the upper left corner,
          4 - bird in the bottom right corner). E.g. all pigeons in a cluster
          can have a representativeGroup value 2 - meaning that we'll only show
          1 pigeon as a representative bird in that cluster. If we didn't have
          representativeGroups, and all pigeons have a high representative
          index, only they would be displayed as representative birds for a
          cluster, even though it contains many different species.
        </FormHelperText>
        <ClusterBirdInput />
      </SimpleForm>
    </CustomClusterCreate>
  )
}

export default ClusterCreate
