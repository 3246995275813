import mime from 'mime-types'
import { Constants } from '../../core'

export const validateModel = (values) => {
  let containsImage = false
  let containsMtl = false
  let containsObj = false

  if (!values) {
    return
  }

  const filteredFiles = values.filter((value) => !!value)

  if (filteredFiles.length !== 3 && filteredFiles.length !== 0) {
    return 'Expected 3 files: mlt, obj, image file (e.g. jpg, png)'
  }

  const errors = filteredFiles.map((value) => {
    const title = value.rawFile ? value.rawFile.name : value.name
    const mimeType = mime.lookup(title)

    if (Constants.ACCEPT_IMAGE.includes(mimeType)) {
      if (containsImage) {
        return 'Model cannot contain more than one image'
      }
      containsImage = true
    }

    if (mimeType === 'model/mtl') {
      if (containsMtl) {
        return 'Model cannot contain more than one mtl file'
      }
      containsMtl = true
    }

    if (mimeType === 'model/obj') {
      if (containsObj) {
        return 'Model cannot contain more than one obj file'
      }
      containsObj = true
    }

    return undefined
  })

  const error = errors.filter((error) => !!error)
  return error[0]
}
