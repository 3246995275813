import { normalize, schema } from 'normalizr'
const schemas = () => {
  return {
    bird: new schema.Entity('bird', undefined, {
      idAttribute: 'id',
    }),
    cluster: new schema.Entity('cluster', undefined, {
      idAttribute: 'id',
    }),
    sounds: new schema.Entity('sounds', undefined, {
      idAttribute: 'id',
    }),
    similarSound: new schema.Entity('similarSound', undefined, {
      idAttribute: 'id',
    }),
  }
}

const normalized = (resource, originalData) => {
  const shema = schemas()[resource]
  const normalizedResource = normalize(originalData, shema)
  const object = normalizedResource.entities[resource]

  return object[normalizedResource.result]
}

const normalizedList = (resource, originalData) => {
  return normalize(originalData, Array.of(schemas()[resource])).entities
}

export { normalized, normalizedList }
