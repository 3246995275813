import { cloneElement } from 'react'
import { useEditController, EditContextProvider, Loading } from 'react-admin'

import { Relationships } from '../../../data'

const CustomBirdEdit = (props) => {
  const controllerProps = useEditController(props)

  const {
    loading,
    record,
    setTransform,
    hasCreate,
    hasShow,
    hasList,
    hasEdit,
    setOnFailure,
    setOnSuccess,
    transformRef,
    onFailureRef,
    onSuccessRef,
    defaultTitle,
    ...other
  } = controllerProps

  const transform = (data) => Relationships.transformData('bird', data, record)
  setTransform(transform)

  if (loading) {
    return <Loading />
  }

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        {cloneElement(props.children, {
          record,
          ...other,
        })}
      </div>
    </EditContextProvider>
  )
}

export default CustomBirdEdit
