import { cloneElement } from 'react'
import { useEditController, EditContextProvider, Loading } from 'react-admin'
import { Relationships } from '../../../data'

const CustomClusterEdit = (props) => {
  const controllerProps = useEditController(props)

  const {
    basePath,
    loading,
    record,
    redirect,
    resource,
    save,
    saving,
    version,
    setTransform,
  } = controllerProps

  const transform = (data) =>
    Relationships.transformData('cluster', data, record)
  setTransform(transform)

  if (loading) {
    return <Loading />
  }

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          transform,
          saving,
          version,
        })}
      </div>
    </EditContextProvider>
  )
}

export default CustomClusterEdit
