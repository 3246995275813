import { Methods, Data } from './services'

/**
 * Cluster Bird Provider
 */
const ClusterBirdProvider = {
  clusterId: null,
  update: function (resource, { originalMedia, newMedia }) {
    Methods.patch(
      `bird/${originalMedia.birdId}/${resource}/${this.clusterId}`,
      newMedia
    )
  },
  create: function (resource, data) {
    Methods.post(`bird/${data.birdId}/${resource}`, {
      ...data,
      clusterId: this.clusterId,
    })
  },
  delete: function (resource, { birdId }) {
    Methods.remove(`bird/${birdId}/${resource}/${this.clusterId}`)
  },
  checkArray: function (resource, { originalArray = [], newArray = [] }) {
    Data.checkArray(resource, {
      originalArray: originalArray.map((bird) => ({
        id: bird.birdId,
        ...bird,
      })),
      newArray: newArray.map((bird) => ({ id: bird.birdId, ...bird })),
      updateCallback: ClusterBirdProvider.update.bind(this),
      createCallback: ClusterBirdProvider.create.bind(this),
      deleteCallback: ClusterBirdProvider.delete.bind(this),
    })
  },
}

export default ClusterBirdProvider
