import * as React from 'react'
import { FormHelperText } from '@material-ui/core'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  TextInput,
  ArrayInput,
  ImageField,
  SimpleFormIterator,
  required,
  maxLength,
} from 'react-admin'
import { useForm } from 'react-final-form'
import {
  SoundPlayer,
  CustomFileInput,
  CustomImageInput,
  Constants,
  SimilarSoundsDialog,
  SimilarSoundsList,
  extractIndex,
} from '../../../core'

const useStyles = makeStyles(() => ({
  addSimilarSoundButton: {
    margin: '10px',
    padding: '3px 8px',
    width: 'fit-content',
  },
}))

const Songs = () => {
  const form = useForm()
  const classes = useStyles()
  const [activeDialog, setActiveDialog] = React.useState(-1)

  const handleAddSimilarSound = (e) =>
    setActiveDialog(extractIndex(e.target.id))

  const handleCloseSimilarSoundsDialog = () => setActiveDialog(-1)

  const handleSubmitSimilarSoundsDialog = (source, similarSound) => {
    const similarSounds =
      form.getState().values.sounds[activeDialog].similarSounds || []

    form.change(`sounds.${activeDialog}.similarSounds`, [
      ...similarSounds,
      similarSound,
    ])

    setActiveDialog(-1)
  }

  const handleRemoveSimilarSound = ({ source, soundIndex, soundId }) => {
    const similarSounds = form.getState().values.sounds[soundIndex]
      .similarSounds

    form.change(
      `${source}.similarSounds`,
      similarSounds.filter((sound) => sound.id !== soundId)
    )
  }

  return (
    <>
      <TextInput
        source='songsDescription'
        label='Overview'
        fullWidth
        validate={maxLength(2500)}
        multiline
      />
      <FormHelperText>
        Captions are displayed as a sound name on bird details, and sound list
        screens.
      </FormHelperText>
      <ArrayInput source='sounds' label=''>
        <SimpleFormIterator>
          <TextInput
            source='caption'
            fullWidth
            label='Caption'
            validate={required()}
          />
          <TextInput
            source='description'
            label='Description'
            fullWidth
            validate={required()}
          />
          <CustomFileInput
            source='file'
            maxSize={20000000}
            accept={Constants.ACCEPT_AUDIO}
            label='File'
            helperText={Constants.MEDIA_TYPE_HELPER_TEXT.AUDIO}
            validate={required()}
          >
            <SoundPlayer />
          </CustomFileInput>
          <CustomImageInput
            source='sonogram'
            label='Sonogram'
            accept={Constants.ACCEPT_IMAGE}
            validate={required()}
          >
            <ImageField source='src' />
          </CustomImageInput>
          <h3>Similar sounds</h3>
          <Button
            className={classes.addSimilarSoundButton}
            label='Add similar sound'
            alignIcon='right'
            startIcon={<AddIcon />}
            onClick={handleAddSimilarSound}
          />
          <SimilarSoundsDialog
            activeDialog={activeDialog}
            handleCloseClick={handleCloseSimilarSoundsDialog}
            handleAddSound={handleSubmitSimilarSoundsDialog}
          />
          <SimilarSoundsList removeSimilarSound={handleRemoveSimilarSound} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export default Songs
