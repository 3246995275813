import * as React from 'react'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import RemoveButton from './RemoveButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  soundFile: {
    '& button': {
      left: -15,
      top: 5,
      display: 'none',
    },
    '&:hover button': {
      display: 'inline-flex',
    },
  },
  file: {
    '& button': {
      display: 'none',
    },
    '&:hover button': {
      display: 'inline-flex',
    },
  },
}))

const FileInputPreview = (props) => {
  const { children, className, file, onRemove, ...rest } = props

  const isSoundPlayer =
    file.rawFile?.type?.includes('audio') || file.src?.includes('sound')

  const classes = useStyles()
  const fileStyle = isSoundPlayer ? classes.soundFile : classes.file

  useEffect(() => {
    return () => {
      const preview = file.rawFile ? file.rawFile.preview : file.preview

      if (preview) {
        window.URL.revokeObjectURL(preview)
      }
    }
  }, [file])

  return (
    <div className={fileStyle} {...rest}>
      <RemoveButton onClick={onRemove} />
      {children}
    </div>
  )
}

FileInputPreview.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  file: PropTypes.object,
}

FileInputPreview.defaultProps = {
  file: undefined,
}

export default FileInputPreview
