import { useCallback, useState } from 'react'
import { TileLayer, FeatureGroup, useMap, Rectangle } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

const LocationMap = ({ onChange, value = [] }) => {
  const map = useMap()
  const [touched, setTouched] = useState(false)

  const handleDeleteRectangle = useCallback(
    (e) => {
      setTouched(true)
      onChange(null)
    },
    [onChange]
  )

  const handleCreateRectangle = useCallback(
    (e) => {
      const northEast = e.layer._bounds._northEast
      const southWest = e.layer._bounds._southWest

      const newBounds = {
        northeast: Object.values(northEast),
        southwest: Object.values(southWest),
      }

      setTouched(true)
      onChange(newBounds)
    },
    [onChange]
  )

  const editValues = {
    edit: false,
    remove: !!value,
  }

  const drawValues = {
    polyline: false,
    polygon: false,
    circle: false,
    marker: false,
    rectangle: !value,
    circlemarker: false,
  }

  const showRectangle = value && !touched

  // TODO: Find a better way to render correctly
  setTimeout(function () {
    map.invalidateSize()
  }, 0)

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contribu4ors'
        url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
      />
      <FeatureGroup>
        <EditControl
          onDeleted={handleDeleteRectangle}
          onCreated={handleCreateRectangle}
          position='topleft'
          edit={editValues}
          draw={drawValues}
          leaflet={{ map }}
        />
        {showRectangle && <Rectangle bounds={Object.values(value)} />}
      </FeatureGroup>
    </>
  )
}

export default LocationMap
