export const shapeChoices = [
  {
    id: 'shape',
    name: 'Shape',
  },
  {
    id: 'behavior',
    name: 'Behavior',
  },
  {
    id: 'color',
    name: 'Color',
  },
  {
    id: 'taxonomical',
    name: 'Taxonomical',
  },
  {
    id: 'sound',
    name: 'Sound',
  },
  {
    id: 'special_characteristics',
    name: 'Special Characteristics',
  },
]

export const birdCategoryChoices = [
  {
    id: 'yard_and_field',
    name: 'Yard and Field',
  },
  {
    id: 'shorebirds',
    name: 'Shorebirds',
  },
  {
    id: 'soaring_and_hunting',
    name: 'Soaring and Hunting',
  },
]
