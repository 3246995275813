import { useMediaQuery } from '@material-ui/core'
import {
  SimpleList,
  EditButton,
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  SearchInput,
  useRefresh,
  useNotify,
  useRedirect,
} from 'react-admin'
import { ListActions, CopyButton, Pagination } from '../../../core'
import DataProvider from '../../../data/DataProvider'

const BirdFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source='name' alwaysOn />
    </Filter>
  )
}

const BirdList = (props) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const redirect = useRedirect()

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleBirdCopyClick = (bird) => {
    DataProvider.copy(bird.id).then(({ json }) => {
      notify('ra.notification.created', 'info', { smart_count: 1 })
      refresh()
      redirect(`/bird/${json.id}`)
    })
  }

  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<BirdFilter />}
      pagination={<Pagination />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => record.imageUrl}
        />
      ) : (
        <Datagrid rowClick='edit'>
          <TextField source='name' />
          <TextField source='genderDescription' />
          <TextField source='sixLetterCode' />
          <TextField source='fourLetterCode' />
          <TextField source='latinName' />
          <DateField source='updatedAt' />
          <EditButton />
          <CopyButton onClick={handleBirdCopyClick} />
        </Datagrid>
      )}
    </List>
  )
}

export default BirdList
