import { cloneElement } from 'react'
import {
  useCreateController,
  CreateContextProvider,
  Loading,
  useNotify,
  useRedirect,
} from 'react-admin'
import { Relationships, DataProvider } from '../../../data'

const CustomBirdCreate = (props) => {
  const controllerProps = useCreateController(props)

  const {
    basePath,
    loading,
    record,
    resource,
    saving,
    version,
  } = controllerProps

  const notify = useNotify()
  const redirect = useRedirect()

  const currentPage = props.location.pathname?.split('/')[3] || ''

  const handleSuccess = (birdId, redirectPath) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })

    if (redirectPath === 'list') return redirect(`/bird`, basePath, birdId)
    else return redirect(`/bird/${birdId}/${currentPage}`, basePath, birdId)
  }

  const resolveError = (
    error,
    defaultMessage = 'ra.notification.http_error'
  ) => {
    if (typeof error === 'string') {
      return error
    }
    return error.message || defaultMessage
  }

  const handleFailure = (error) => {
    notify(resolveError(error), 'warning', {
      _: resolveError(error, undefined),
    })
  }

  const handleSaveBird = (birdData, redirectPath) => {
    DataProvider.create('bird', {
      data: birdData,
    })
      .then(({ data }) =>
        Relationships.updateRelationships('bird', data).then(() => data.id)
      )
      .then((id) => handleSuccess(id, redirectPath))
      .catch(handleFailure)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <CreateContextProvider value={controllerProps}>
      <div>
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          saving,
          save: handleSaveBird,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

export default CustomBirdCreate
