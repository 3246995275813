import * as React from 'react'
import { Button } from 'react-admin'
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import DataProvider from '../../data/DataProvider'
import MediaProvider from '../../data/MediaProvider'

const useStyles = makeStyles((theme) => ({
  dialogContentWrap: {
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: 'red',
  },
  button: {
    '& > span': {
      paddingRight: '0.5em',
    },
  },
}))

const SimilarSoundsDialog = ({
  source,
  activeDialog,
  handleCloseClick,
  handleAddSound,
  ...rest
}) => {
  const [soundId, setSoundId] = React.useState(0)
  const [description, setDescription] = React.useState('')
  const [searchChoices, setSearchChoices] = React.useState([])
  const [searchBirdChoices, setSearchBirdChoices] = React.useState([])
  const [error, setError] = React.useState('')
  const classes = useStyles(rest)

  const resetFields = () => {
    setError('')
    setSoundId(0)
    setDescription('')
    setSearchChoices([])
    setSearchBirdChoices([])
  }

  const handleSelectBirdOnChange = (e) => {
    const value = e.target.value

    if (!value || typeof value !== 'string' || value.length < 3) {
      return
    }

    DataProvider.getList('bird', {
      pagination: { page: 1, perPage: 15 },
      sort: { field: 'id', order: 'ASC' },
      filter: { name: value },
    }).then(({ data }) => {
      setSearchBirdChoices(data)
    })
  }

  const handleSelectBird = (e) => {
    if (typeof e.target.value !== 'number') {
      return
    }

    setSearchChoices([])
    setSoundId(0)

    DataProvider.getOne('bird', { id: e.target.value }).then(({ data }) => {
      if (!data.sounds.length) {
        return
      }

      MediaProvider.getMediaArray('sound', data.sounds).then((res) =>
        setSearchChoices(res)
      )
    })
  }

  const handleSoundOnChange = (e) => setSoundId(e.target.value)

  const handleDescriptionOnChange = (e) => setDescription(e.target.value)

  const handleAddSoundClick = () => {
    if (!soundId || !description) {
      setError('All fields are required')
      return
    }

    handleAddSound(source, {
      id: soundId,
      description,
    })
    resetFields()
  }

  const handleCancelClick = () => {
    handleCloseClick()
    resetFields()
  }

  return (
    <Dialog fullWidth open={activeDialog !== -1}>
      <DialogTitle>Add similar song</DialogTitle>
      <DialogContent className={classes.dialogContentWrap}>
        <Autocomplete
          freeSolo
          onChange={handleSelectBird}
          onInputChange={handleSelectBirdOnChange}
          options={searchBirdChoices}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props} value={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label='Select bird' variant='filled' />
          )}
        />
        <TextField
          select
          fullWidth
          variant='filled'
          label='Select sound'
          onChange={handleSoundOnChange}
          disabled={!searchChoices.length}
          value={soundId}
        >
          {searchChoices.map((choice) => (
            <MenuItem value={choice.id}>{choice.caption}</MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          multiline
          label='Description'
          variant='filled'
          value={description}
          onChange={handleDescriptionOnChange}
        />
        <span className={classes.errorMessage}>{error}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} label='Cancel' />
        <Button
          className={classes.button}
          variant='contained'
          label='Add'
          onClick={handleAddSoundClick}
        />
      </DialogActions>
    </Dialog>
  )
}

export default SimilarSoundsDialog
