import * as React from 'react'
import { FormHelperText } from '@material-ui/core'
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin'

const Aliases = () => {
  return (
    <>
      <FormHelperText>
        Aliases can be used as a search parameter.
      </FormHelperText>
      <ArrayInput source='aliases' label=''>
        <SimpleFormIterator>
          <TextInput fullWidth validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export default Aliases
