import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}))

const DefaultAddButton = (props) => {
  const classes = useStyles(props)

  return (
    <Button size='small' {...props}>
      <AddIcon className={classes.leftIcon} />
      Add
    </Button>
  )
}

export default DefaultAddButton
