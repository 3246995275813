import { Methods } from './services'

/**
 * Similar sound provider
 */
const SimilarSoundProvider = {
  birdId: null,

  getSimilarSounds: (sound) => {
    const url = `sixlettercode/${sound.sixLetterCode}/sound/${sound.id}/similar`
    return Methods.get(url).then(({ json }) => {
      return json
    })
  },
  createUrl: (soundId, similarSound = {}, sixLetterCode) => {
    const url = `sixlettercode/${sixLetterCode}/sound/${soundId}/similar`
    return soundId ? `${url}/${similarSound.id}` : url
  },

  create: (soundId, similarSound) => {
    const url = SimilarSoundProvider.createUrl(soundId, similarSound)
    return Methods.post(url, { description: similarSound.description })
  },

  delete: (soundId, similarSound) => {
    const url = SimilarSoundProvider.createUrl(soundId, similarSound)
    return Methods.remove(url)
  },
}

export default SimilarSoundProvider
