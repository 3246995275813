import React from 'react'
import {
  NumberInput,
  TextInput,
  required,
  maxLength,
  number,
  minValue,
  maxValue,
  regex,
  ImageField,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { Box } from '@material-ui/core'
import {
  Constants,
  CustomImageInput,
  Validation,
  SimilarBirdsInput,
} from '../../../core'

const MIN_BIRD_RATIO_SIZE = '1'
const MAX_BIRD_RATIO_SIZE = '100'

const BasicInformation = () => {
  const form = useForm()
  const formState = useFormState()

  const handleNumberChange = (event) => {
    if (event.target.validationMessage === 'Please enter a number.') {
      form.change(event.target.name, formState.values[event.target.name])
    }
  }

  return (
    <Box>
      <Box flex={1} display='flex'>
        <Box flex={1} mr='4em'>
          <TextInput
            source='name'
            resource='bird'
            validate={[maxLength(255), required()]}
            fullWidth
          />
          <TextInput
            source='sixLetterCode'
            helperText='Can be used as a search parameter.'
            validate={[
              ...Validation.length(6),
              regex(/^[A-Z]*$/, '(Uppercase) letters only'),
              required(),
            ]}
            fullWidth
          />
          <TextInput
            source='fourLetterCode'
            helperText='Can be used as a search parameter. Birds with the same 4 letter code, will be displayed on bird details screen under the Gender/Ages section.'
            validate={[
              ...Validation.length(4),
              regex(/^[A-Z]*$/, '(Uppercase) letters only'),
              required(),
            ]}
            fullWidth
          />
          <TextInput
            source='genderDescription'
            helperText='Used as a bird description on bird details screen under the Gender/Ages section.'
            validate={[maxLength(255), required()]}
            fullWidth
          />
          <TextInput
            source='latinName'
            validate={[maxLength(255), required()]}
            fullWidth
            helperText='Shown on bird details screen under the bird name.'
          />
          <NumberInput
            source='sizeRatio'
            step='0.001'
            max={MAX_BIRD_RATIO_SIZE}
            min={MIN_BIRD_RATIO_SIZE}
            placeholder={MIN_BIRD_RATIO_SIZE}
            helperText={`Used to describe bird size, relative to the smallest/largest bird. ${MIN_BIRD_RATIO_SIZE} - smallest bird, ${MAX_BIRD_RATIO_SIZE} - largest bird. 3D models displayed on cluster details are scaled based on this value.`}
            onChange={handleNumberChange}
            validate={[
              number(),
              minValue(
                MIN_BIRD_RATIO_SIZE,
                `Must be bigger than or equal ${MIN_BIRD_RATIO_SIZE}`
              ),
              maxValue(
                MAX_BIRD_RATIO_SIZE,
                `Must be smaller than or equal ${MAX_BIRD_RATIO_SIZE}`
              ),
              required(),
            ]}
            fullWidth
          />
        </Box>
        <Box flex={1}>
          <CustomImageInput
            label='Thumbnail image'
            source='thumbnailImage.file'
            accept={Constants.ACCEPT_IMAGE}
          >
            <ImageField source='src' />
          </CustomImageInput>
        </Box>
      </Box>
      <Box>
        <TextInput
          source='interestingFacts'
          multiline
          fullWidth
          helperText='Shown on bird details screen.'
        />
      </Box>
      <Box>
        <SimilarBirdsInput />
      </Box>
    </Box>
  )
}

export default BasicInformation
