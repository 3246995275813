import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'

const useStyles = makeStyles((theme) => ({
  marginRight: theme.spacing(1),
}))

const DefaultRemoveButton = (props) => {
  const classes = useStyles(props)

  return (
    <Button size='small' {...props}>
      <CloseIcon className={classes} />
    </Button>
  )
}

export default DefaultRemoveButton
