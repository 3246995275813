import * as React from 'react'
import { FormHelperText } from '@material-ui/core'
import {
  TextInput,
  ArrayInput,
  ImageField,
  SimpleFormIterator,
  required,
} from 'react-admin'
import { CustomImageInput, Constants } from '../../../core'

const Images = () => {
  return (
    <>
      <FormHelperText>
        Image captions are displayed on bird details screen.
      </FormHelperText>
      <h3>Gallery Images</h3>
      <ArrayInput source='galleryImages' label=''>
        <SimpleFormIterator>
          <TextInput
            source='caption'
            fullWidth
            multiline
            label='Caption'
            validate={required()}
          />
          <CustomImageInput
            accept={Constants.ACCEPT_IMAGE}
            label=''
            source='file'
          >
            <ImageField source='src' />
          </CustomImageInput>
        </SimpleFormIterator>
      </ArrayInput>
      <h3>Map Images</h3>
      <ArrayInput source='mapImages' label=''>
        <SimpleFormIterator>
          <TextInput
            source='caption'
            label='Caption'
            fullWidth
            multiline
            validate={required()}
          />
          <CustomImageInput
            accept={Constants.ACCEPT_IMAGE}
            label=''
            source='file'
          >
            <ImageField source='src' />
          </CustomImageInput>
        </SimpleFormIterator>
      </ArrayInput>
      <h3>Id Point Images</h3>
      <ArrayInput source='idPointImages' label=''>
        <SimpleFormIterator>
          <TextInput
            source='caption'
            fullWidth
            multiline
            label='Caption'
            validate={required()}
          />
          <CustomImageInput label='' source='file'>
            <ImageField source='src' />
          </CustomImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export default Images
