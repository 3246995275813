import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'

import { Authentication } from './services'

const authProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    return Authentication.authenticate(username, password)
  }

  if (type === AUTH_LOGOUT) {
    return Authentication.logout()
  }

  if (type === AUTH_ERROR) {
    const { status } = params

    if (status === 401 || status === 403) {
      return Authentication.reauthenticate().catch(() => {
        Authentication.logout()
        return Promise.reject()
      })
    }

    return Promise.resolve()
  }

  if (type === AUTH_CHECK) {
    if (!Authentication.isValid()) {
      return Promise.reject()
    }

    return Promise.resolve()
  }

  return Promise.resolve()
}

export default authProvider
