import { makeStyles } from '@material-ui/core'

export const useCurrentPageStyles = (page) =>
  makeStyles(() => {
    const propName = `& .MuiButton-textSizeSmall[data-page="${page - 1}"]`

    return {
      list: {
        [propName]: {
          backgroundColor: '#E0E0E0',
        },
      },
    }
  })
