import { getRefreshToken, getAccessToken } from './methods'
import {
  getAccessTokenFromStorage,
  setAccessTokenToStorage,
  removeAccessTokenFromStorage,
  getRefreshTokenFromStorage,
  setRefreshTokenToStorage,
  removeRefreshTokenFromStorage,
} from './authenticationStorage'

const fetchRefreshToken = (username, password) => {
  return getRefreshToken(username, password).then(({ json }) => {
    const { token: refreshToken } = json
    return refreshToken
  })
}

const fetchAccessToken = (refreshToken) => {
  return getAccessToken(refreshToken).then(({ json }) => {
    const { token: accessToken } = json
    return accessToken
  })
}

const obtainRefreshToken = (username, password) => {
  return fetchRefreshToken(username, password).then((refreshToken) => {
    setRefreshTokenToStorage(refreshToken)
    return refreshToken
  })
}

const obtainAccessToken = (refreshToken) => {
  return fetchAccessToken(refreshToken).then(setAccessTokenToStorage)
}

export const authenticate = (username, password) => {
  return obtainRefreshToken(username, password).then(obtainAccessToken)
}

export const reauthenticate = () => {
  const refreshToken = getRefreshTokenFromStorage()
  return obtainAccessToken(refreshToken)
}

export const logout = () => {
  removeRefreshTokenFromStorage()
  removeAccessTokenFromStorage()
  return Promise.resolve()
}

export const isValid = () => {
  return getRefreshTokenFromStorage() && getAccessTokenFromStorage()
}
