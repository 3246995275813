import DataProvider from '../DataProvider'
import MediaProvider from '../MediaProvider'
import ModelProvider from '../ModelProvider'
import ClusterBirdProvider from '../ClusterBirdProvider'
import SimilarSoundProvider from '../SimilarSoundProvider'
async function getBirdRelationships(data) {
  let thumbnailImage = null

  MediaProvider.birdId = data.id
  ModelProvider.birdId = data.id
  SimilarSoundProvider.birdId = data.id

  const modelId = data.modelIds.length > 0 ? data.modelIds[0] : null
  const mapImages = await MediaProvider.getMaps(data.sixLetterCode)
  const birds = DataProvider.getList('bird')
  const galleryImages = await MediaProvider.getMediaArray(
    'image',
    data.galleryPhotos
  )
  const idPointImages = await MediaProvider.getMediaArray(
    'image',
    data.idPoints
  )
  const modelFiles = ModelProvider.getModel(modelId)
  const videos = await MediaProvider.getMediaArray('video', data.videos)
  const sounds = await MediaProvider.getSounds(data.sixLetterCode)
  const similarSounds = sounds.map((sound) =>
    SimilarSoundProvider.getSimilarSounds(sound)
  )

  if (data.thumbnailId) {
    thumbnailImage = MediaProvider.getMedia('image', {
      id: data.thumbnailId,
    })
  }

  return Promise.all([
    mapImages,
    birds,
    galleryImages,
    thumbnailImage,
    videos,
    sounds,
    idPointImages,
    modelFiles,
    Promise.all(similarSounds),
  ]).then((results) => {
    const allSounds = results[5].map((sound, key) => {
      return {
        ...sound,
        sonogram: { src: sound.sonogramUrl },
        similarSounds: results[8][key],
      }
    })

    return {
      mapImages: results[0],
      allBirds: results[1].data,
      similarBirds: results[1].data.filter((bird) =>
        data.similarBirdIds.includes(bird.id)
      ),
      galleryImages: results[2],
      thumbnailImage: results[3],
      videos: results[4],
      sounds: allSounds,
      idPointImages: results[6],
      modelFiles: results[7],
    }
  })
}

async function getClusterRelationships(data) {
  MediaProvider.birdId = data.id
}

async function getRelationships(resource, data) {
  switch (resource) {
    case 'bird': {
      return getBirdRelationships(data)
    }
    case 'cluster': {
      return getClusterRelationships(data)
    }
    default:
      return {}
  }
}

function updateBirdRelationships(data, record) {
  const {
    thumbnailImage: originalThumbnailImage = null,
    mapImages: originalMapImages = [],
    galleryImages: originalGalleryImages = [],
    videos: originalVideos = [],
    sounds: originalSounds = [],
    idPointImages: originalIdPointImages = [],
    modelIds = [],
  } = record
  const {
    mapImages: newMapImages,
    galleryImages: newGalleryImages,
    thumbnailImage: newThumbnailImage,
    videos: newVideos,
    sounds: newSounds,
    modelFiles: newModelFiles,
    idPointImages: newIdPointImages,
    sixLetterCode,
  } = data

  const modelId = modelIds && modelIds.length > 0 ? modelIds[0] : null

  SimilarSoundProvider.birdId = data.id
  MediaProvider.birdId = data.id
  ModelProvider.birdId = data.id

  return Promise.all([
    MediaProvider.updateSingleMedia('image', {
      imageType: 'thumbnail',
      originalMedia: originalThumbnailImage,
      newMedia: newThumbnailImage,
    }),
    ModelProvider.checkModel(modelId, newModelFiles),
    MediaProvider.updateMap(sixLetterCode, {
      originalArray: originalMapImages,
      newArray: newMapImages,
    }),
    MediaProvider.updateMediaArray('image', {
      imageType: 'galleryPhoto',
      originalArray: originalGalleryImages,
      newArray: newGalleryImages,
    }),
    MediaProvider.updateMediaArray('image', {
      imageType: 'idPoint',
      originalArray: originalIdPointImages,
      newArray: newIdPointImages,
    }),
    MediaProvider.updateMediaArray('video', {
      originalArray: originalVideos,
      newArray: newVideos,
    }),
    MediaProvider.updateAudioArray('sound', {
      originalArray: originalSounds,
      newArray: newSounds,
    }),
  ])
}

function updateClusterRelationships(data, record) {
  const { birds: originalBirds = [] } = record
  const { birds: newBirds } = data

  ClusterBirdProvider.clusterId = data.id
  ClusterBirdProvider.checkArray('cluster', {
    originalArray: originalBirds,
    newArray: newBirds,
  })
}

const transformBird = async (data, record = {}) => {
  const clonedData = Object.assign({}, data)

  if (data.id) {
    await updateBirdRelationships(data, record)
  }

  if (data.region && data.region[0] === null) {
    clonedData.region = null
  }

  delete clonedData.allBirds
  delete clonedData.similarBirds
  delete clonedData.mapImages
  delete clonedData.thumbnailImage
  delete clonedData.galleryImages
  delete clonedData.sounds
  delete clonedData.videos
  delete clonedData.idPointImages
  delete clonedData.modelFiles

  return clonedData
}

const transformCluster = (data, record) => {
  const clonedData = Object.assign({}, data)

  if (data.id) {
    updateClusterRelationships(data, record)
  }

  delete clonedData.allBirds

  return clonedData
}

const transformSixLetterCode = (data, record) => {
  const clonedData = Object.assign({}, data)

  if (data.id) {
  }

  return clonedData
}

const transformData = (resource, data, record) => {
  switch (resource) {
    case 'bird': {
      return transformBird(data, record)
    }
    case 'cluster': {
      return transformCluster(data, record)
    }
    case 'sixlettercode': {
      return transformSixLetterCode(data, record)
    }
    default:
      return {}
  }
}

const updateRelationships = (resource, data, record = {}) => {
  switch (resource) {
    case 'bird': {
      return updateBirdRelationships(data, record)
    }
    case 'cluster': {
      return updateClusterRelationships(data, record)
    }
    default:
      return {}
  }
}

export { getRelationships, transformData, updateRelationships }
