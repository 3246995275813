import * as React from 'react'
import { FormHelperText } from '@material-ui/core'
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  required,
} from 'react-admin'
import {
  VideoPlayer,
  CustomFileInput,
  CustomImageInput,
  Constants,
} from '../../../core'

const Videos = () => {
  return (
    <>
      <FormHelperText>
        Captions are displayed when playing a video in gallery.
      </FormHelperText>
      <ArrayInput source='videos' label=''>
        <SimpleFormIterator>
          <TextInput
            source='caption'
            label='Caption'
            fullWidth
            validate={required()}
          />
          <CustomFileInput
            accept={Constants.ACCEPT_VIDEO}
            label='File'
            source='file'
            maxSize={20000000}
            fullWidth
            validate={required()}
            helperText={Constants.MEDIA_TYPE_HELPER_TEXT.VIDEO}
          >
            <VideoPlayer />
          </CustomFileInput>
          <CustomImageInput
            accept={Constants.ACCEPT_IMAGE}
            label='Thumbnail'
            source='videoThumbnail'
            validate={required()}
          >
            <ImageField source='src' />
          </CustomImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export default Videos
