import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import FileCopy from '@material-ui/icons/FileCopy'
import { Link } from 'react-router-dom'

const CopyButton = ({
  basePath = '',
  icon = defaultIcon,
  label = 'Copy',
  record,
  onClick,
  scrollToTop = true,
  ...rest
}) => {
  const [loading, setLoading] = React.useState(false)
  const handleClick = (e) => {
    stopPropagation(e)
    setLoading(true)

    if (onClick) {
      onClick(record)
    }
  }

  return (
    <Button
      component={Link}
      to='/'
      label={label}
      onClick={handleClick}
      {...rest}
    >
      {loading && <CircularProgress size='1rem' />}
      {!loading && icon}
    </Button>
  )
}

const defaultIcon = <FileCopy />

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation()

CopyButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  loading: PropTypes.bool,
  scrollToTop: PropTypes.bool,
}

export default CopyButton
