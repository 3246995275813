import { CreateButton, ExportButton, TopToolbar } from 'react-admin'

const ListActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <ExportButton />
  </TopToolbar>
)

export default ListActions
