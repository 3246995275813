import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  SearchInput,
} from 'react-admin'
import { ListActions, Pagination } from '../../../core'

const ClusterFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source='name' alwaysOn />
    </Filter>
  )
}

const ClusterList = (props) => (
  <>
    <List
      {...props}
      actions={<ListActions />}
      filters={<ClusterFilter />}
      pagination={<Pagination />}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' />
        <TextField source='birdCategory' />
        <TextField source='clusterType' />
        <EditButton />
      </Datagrid>
    </List>
  </>
)

export default ClusterList
