import { fetchUtils, HttpError } from 'react-admin'

import * as Data from './data'
import { getAccessTokenFromStorage } from './authenticationStorage'

const apiUrl = process.env.REACT_APP_BASE_URL
const httpClient = fetchUtils.fetchJson

const handleApiError = async (error) => {
  const { status, statusText, body } = error

  if (body) {
    const { errors } = body

    if (errors && errors[0]) {
      return Promise.reject(new HttpError(errors[0].detail, status))
    }
  }

  try {
    const json = JSON.parse(body)

    return Promise.reject(new HttpError(statusText, status, json))
  } catch (e) {
    return Promise.reject(new HttpError(statusText, status))
  }
}

const get = (url, query) => {
  const headers = new Map([
    ['Content-Type', 'application/vnd.api+json'],
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])

  if (query) {
    return httpClient(`${apiUrl}/${url}?${query}`, {
      headers,
    }).catch(handleApiError)
  }

  return httpClient(`${apiUrl}/${url}`, {
    headers,
  }).catch(handleApiError)
}

const patch = (url, data) => {
  const headers = new Map([
    ['Content-Type', 'application/vnd.api+json'],
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])

  return httpClient(`${apiUrl}/${url}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers,
  }).catch(handleApiError)
}

const post = (url, data) => {
  const headers = new Map([
    ['Content-Type', 'application/vnd.api+json'],
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])

  let newData = { ...data }

  if (url === 'bird' && !data.hasOwnProperty('aliases')) {
    newData.aliases = []
  }

  return httpClient(`${apiUrl}/${url}`, {
    method: 'POST',
    body: JSON.stringify(newData),
    headers,
  }).catch(handleApiError)
}

const getFile = (url) => {
  const headers = new Map([
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])

  return fetch(`${apiUrl}/${url}`, { method: 'GET', headers })
    .then((response) => response.blob())
    .catch(handleApiError)
}

const putFile = (url, file) => {
  const headers = new Map([
    ['Accept', '*/*'],
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])
  const formData = Data.createFormData(file)

  return httpClient(`${apiUrl}/${url}`, {
    method: 'PUT',
    body: formData,
    headers,
  }).catch(handleApiError)
}

const remove = (url) => {
  const headers = new Map([
    ['Content-Type', 'application/vnd.api+json'],
    ['Authorization', `Bearer ${getAccessTokenFromStorage()}`],
  ])

  return httpClient(`${apiUrl}/${url}`, {
    method: 'DELETE',
    headers,
  }).catch(handleApiError)
}

const getToken = (authorization) => {
  const headers = new Map([['Authorization', authorization]])

  return httpClient(`${apiUrl}/auth/tokens`, {
    method: 'POST',
    headers,
  }).catch(handleApiError)
}

const getRefreshToken = (username, password) => {
  const authorization = `Basic ${btoa(`${username}:${password}`)}`
  return getToken(authorization)
}

const getAccessToken = (refreshToken) => {
  const authorization = `Bearer ${refreshToken}`
  return getToken(authorization)
}

export {
  get,
  getFile,
  putFile,
  patch,
  remove,
  post,
  getRefreshToken,
  getAccessToken,
}
