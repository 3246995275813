import * as React from 'react'
import { TabbedForm, FormTab } from 'react-admin'

import { MapContainer } from '../../../core'
import {
  Songs,
  Videos,
  Images,
  Aliases,
  BasicInformation,
  Model,
} from '../components'

import CustomBirdEdit from './CustomBirdEdit'
import Toolbar from './Toolbar'

const BirdEdit = ({ id, ...props }) => {
  return (
    <CustomBirdEdit id={id} mutationMode='pessimistic' {...props}>
      <TabbedForm
        variant='outlined'
        margin='dense'
        className='form'
        sanitizeEmptyValues={false}
        toolbar={<Toolbar />}
      >
        <FormTab label='Information'>
          <BasicInformation />
        </FormTab>
        <FormTab label='Aliases'>
          <Aliases />
        </FormTab>
        <FormTab label='Images'>
          <Images />
        </FormTab>
        <FormTab label='Videos'>
          <Videos />
        </FormTab>
        <FormTab label='Songs'>
          <Songs />
        </FormTab>
        <FormTab label='Location'>
          <MapContainer source='region[0]' />
        </FormTab>
        <FormTab label='Model'>
          <Model />
        </FormTab>
      </TabbedForm>
    </CustomBirdEdit>
  )
}

export default BirdEdit
