import React, { useState, useEffect } from 'react'
import {
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  AutocompleteInput,
  required,
  minValue,
  maxValue,
  useDataProvider,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { Options, Validation } from '../../../core'

const ClusterBirdInput = (props) => {
  const form = useForm()
  const formState = useFormState()
  const [birds, setBirds] = useState([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider.getList('bird').then(({ data }) => {
      setBirds(data)
    })
  }, [dataProvider])

  const handleNumberChange = (event) => {
    const names = event.target.name.split(/[.[\]]{1,2}/)

    if (event.target.validationMessage === 'Please enter a number.') {
      form.change(
        event.target.name,
        formState.values[names[0]][names[1]][names[2]]
      )
    }
  }

  const birdNameValidation = (value, allValues) => {
    const idsList = allValues.birds?.filter((bird) => bird?.birdId === value)

    if (idsList?.length > 1) return 'Bird already in cluster'

    return undefined
  }

  return (
    <ArrayInput source='birds' label=''>
      <SimpleFormIterator>
        <AutocompleteInput
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 2
          }}
          suggestionLimit={10}
          fullWidth
          label='Bird name'
          source='birdId'
          validate={[required(), birdNameValidation]}
          choices={birds}
          optionText={Options.optionRenderer}
          optionValue='id'
          helperText='Type at least three letters for suggestions to pop up.'
        />
        )
        <NumberInput
          fullWidth
          helperText='Used for displaying representative birds for a cluster. 1 - not very representative, 100 - highly representative.'
          source='representativeIndex'
          label='Representative index'
          min={1}
          max={100}
          step={1}
          onChange={handleNumberChange}
          validate={[
            required(),
            Validation.isInteger,
            minValue(1),
            maxValue(100),
          ]}
        />
        <NumberInput
          fullWidth
          source='representativeGroup'
          label='Representative group'
          min={1}
          max={4}
          step={1}
          onChange={handleNumberChange}
          validate={[
            required(),
            Validation.isInteger,
            minValue(1),
            maxValue(4),
          ]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default ClusterBirdInput
