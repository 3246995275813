import { Methods } from './services'

var JSZip = require('jszip')
var JSZipUtils = require('jszip-utils')
var mime = require('mime-types')

const apiUrl = process.env.REACT_APP_BASE_URL
const apiKeyCMS = process.env.REACT_APP_API_KEY_CMS

const ModelProvider = {
  birdId: null,

  async getModel(id) {
    if (!id) {
      return
    }

    const zip = new JSZip()
    const sourcePath = `${apiUrl}/bird/${this.birdId}/model/${id}?apiKey=${apiKeyCMS}`
    const binary = await JSZipUtils.getBinaryContent(sourcePath)

    if (!binary) {
      return []
    }

    const results = await zip.loadAsync(binary)

    const values = Object.values(results.files)
    const files = values.filter(
      (value) =>
        value.dir !== true &&
        !value.name.includes('__MACOSX') &&
        !value.name.includes('.DS_Store')
    )

    const base64Files = files.map((file) =>
      zip
        .file(file.name)
        .async('base64')
        .then((result) => {
          const type = mime.lookup(file.name)

          return {
            name: file.name,
            src: 'data:' + type + ';base64,' + result,
          }
        })
    )

    return Promise.all(base64Files)
  },

  async zipModel(files) {
    const zip = new JSZip()

    if (!files) {
      return null
    }

    files.forEach((file) => {
      const title = file.rawFile ? file.rawFile.name : file.name
      zip.file(`model/${title}`, file.rawFile)
    })

    const zipBlob = await zip.generateAsync({ type: 'blob' })
    return new File([zipBlob], 'model.zip', { type: 'application/zip' })
  },

  async checkModel(id, newModelFiles) {
    let shouldUpdate = false

    if (!newModelFiles) {
      return
    }

    // Create
    if (!id) {
      const zipped = await this.zipModel(newModelFiles)
      return this.createModel({ rawFile: zipped })
    }

    // Delete
    if (id && newModelFiles.length === 0) {
      return this.deleteModel('model', { id })
    }

    newModelFiles.forEach((file) => {
      if (file.rawFile) {
        shouldUpdate = true
      }
    })

    // Update (no replacements)
    if (shouldUpdate) {
      const zipped = await this.zipModel(newModelFiles)
      await this.deleteModel('model', { id })
      await this.createModel({ rawFile: zipped })
    }
  },

  createModel({ rawFile }) {
    return Methods.putFile(`bird/${this.birdId}/model`, rawFile)
  },

  deleteModel(resource, { id }) {
    return Methods.remove(`bird/${this.birdId}/${resource}/${id}`)
  },
}

export default ModelProvider
