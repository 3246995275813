import { useState } from 'react'
import ReactPlayer from 'react-player'
import Alert from '@material-ui/lab/Alert'

const SoundPlayer = ({ record }) => {
  const source = record.src || record.undefined
  const [error, setError] = useState(false)

  if (!source) {
    return null
  }

  if (error) {
    return <Alert severity='error'>Error trying to load sound</Alert>
  }

  const handleError = () => {
    setError(true)
  }

  return (
    <div style={{ float: 'left' }}>
      <ReactPlayer
        onError={handleError}
        url={source}
        width='400px'
        height='50px'
        playing={false}
        controls={true}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
    </div>
  )
}

export default SoundPlayer
